import la from '../LazyComponent';

const Messages = la(() => import('@/pages/Messages'));

const MessagesRoutes = [
  {
    path: '/messages',
    element: Messages,
    name: 'menu.Message',
    meta: {
      key: 'menu.sws.messages',
    },
  },
];

export default MessagesRoutes;
