import { createModel } from '@rematch/core';
import type { RootModel } from '.';

export const historyTabs = createModel<RootModel>()({
  state: {
    routerList: JSON?.parse(localStorage.getItem('historyTabs') || 'false'),
    componentList: [],
  },
  reducers: {
    setRouterList: (state, payload) => {
      localStorage.setItem('historyTabs', JSON.stringify(payload));
      return {
        ...state,
        routerList: payload,
      };
    },
    setComponentList: (state, payload) => {
      return {
        ...state,
        componentList: payload,
      };
    },
  },
  effects: (dispatch) => ({}),
});
